import {
  Flex,
  Box,
  Text,
  Button,
  Heading,
  Grid,
  GridItem,
  Card,
  CardBody,
} from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import './ReferralPage.scss';
import { useNavigate } from 'react-router-dom';

export const Page = () => {
  const navigate = useNavigate();

  const render = () => {
    return (
      <Grid templateRows="1fr, 1fr, 1fr, 1fr, 1fr" gap={4}>
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Referral
          </Heading>
        </GridItem>
        <GridItem rowStart={2} colSpan={2}>
          <Card>
            <CardBody>
              <Text>
                Make a referral to another organisation in your network.
              </Text>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem rowStart={3} colStart={1}>
          <Flex direction="row" columnGap="2em">
            <Button
              colorScheme="teal"
              size="md"
              onClick={() => navigate('/refer-human')}
            >
              Make a referral
            </Button>
            <Button
              colorScheme="teal"
              size="md"
              onClick={() => navigate('/services')}
            >
              View Available Services
            </Button>
            <Button
              colorScheme="teal"
              size="md"
              onClick={() => navigate('/referral/inbox')}
            >
              Check Inbox
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    );
  };

  return (
    <Box w={'100%'} padding={'2rem'} overflowY={'auto'}>
      <Flex className="DeduplicationPage" position={'relative'}>
        {render()}
      </Flex>
    </Box>
  );
};

export const ReferralPage = withAuthProtection(Page);
