export enum PermissionEnum {
  View = 'view',
  Edit = 'edit',
  ManageSharing = 'manageSharing',
}
export enum LengthEnum {
  Seven = '7',
  Thirty = '30',
  Sixty = '60',
}

export interface ISharedFiles {
  name: string;
  uploader: string;
  sharedWith: ISharedWith[];
  content: string;
  format: string;
  organization: string;
  dateModified: string;
}
export interface IContent {
  content: string;
  format: string;
}
export interface ISharedWith {
  email: string;
  permission: PermissionEnum;
  length: LengthEnum;
}
