import { Outlet } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { Navbar } from '../../components/navigation/Navbar';
import { Toaster } from 'react-hot-toast';
import { configuration } from '../../../config/configuration';

export const MainLayout = () => {
  return (
    <Box
      as="section"
      height="100vh"
      overflowY="hidden"
      display="flex"
      flexDirection="row"
    >
      <Navbar />
      <Box className="page-container" backgroundSize="cover">
        <Box position={'absolute'} top={'1em'} right={'1em'}>
          <Text fontSize={'xl'}>
            {configuration.portalConfigurations.navbarRightTextLogo}
          </Text>
        </Box>
        <Outlet />
      </Box>

      <Toaster position="top-center" reverseOrder={false} />
    </Box>
  );
};
