import {
  Flex,
  Box,
  Text,
  Button,
  ButtonGroup,
  Heading,
  Grid,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Select,
  Progress,
} from '@chakra-ui/react';
import _ from 'lodash';
import { withAuthProtection } from '../../services/protect-route-element';
import './DeduplicationPage.scss';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { configuration } from '../../../config/configuration';

enum DeduplicationPageState {
  EMPTY,
  UPLOAD_DATA,
  CONFIRM_DATA,
  REVIEW_RESULTS,
  LOADING,
}

interface UploadResponse {
  added_to_registry: number;
  potential_duplicated: number;
  success: boolean;
  total: number;
  working_id: string;
}

interface ConfirmResponse {
  success: boolean;
}

export const Page = () => {
  const [currentState, setCurrentState] = useState<DeduplicationPageState>(
    DeduplicationPageState.EMPTY
  );

  const [isConfirming, setIsConfirming] = useState(false);

  const inputRef = useRef(null);

  const [uploadFileName, setUploadFileName] = useState('');

  const [payload, setPayload] = useState('');

  const [downloadUri, setDownloadUri] = useState('');

  const [results, setResults] = useState<string[]>([]);

  const isFormReadyForDeduplication = () => {
    return uploadFileName.length > 0 && payload;
  };

  useEffect(() => {});

  const renderEmptyState = () => {
    return (
      <Grid
        templateRows="1fr, 1fr, 1fr, 1fr, 1fr"
        templateColumns="1fr, 1fr"
        gap={4}
      >
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Deduplication
          </Heading>
        </GridItem>
        <GridItem rowStart={2} colSpan={1}>
          <Heading as="h2" size="md" noOfLines={1}>
            Please choose an option:
          </Heading>
        </GridItem>
        <GridItem rowStart={3} colSpan={2}>
          <Card>
            <CardBody>
              <Text>
                Upload your beneficiary list to check against the shared
                registry
              </Text>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem rowStart={4} colStart={1}>
          <Flex direction="row" columnGap="2em">
            <Button
              colorScheme="teal"
              size="md"
              onClick={() => {
                setIsConfirming(false);
                setCurrentState(DeduplicationPageState.UPLOAD_DATA);
              }}
            >
              Upload beneficiary list
            </Button>
          </Flex>
        </GridItem>
        <GridItem rowStart={5} colSpan={2}>
          <Card>
            <CardBody>
              <Text>
                Upload your revised list of potential duplicates to the registry
              </Text>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem rowStart={6} colStart={1}>
          <Flex direction="row" columnGap="2em">
            <Button
              colorScheme="teal"
              size="md"
              onClick={() => {
                setIsConfirming(true);
                setCurrentState(DeduplicationPageState.CONFIRM_DATA);
              }}
            >
              Upload revised list
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    );
  };

  const renderPrepareDataUpload = () => {
    return (
      <Grid templateRows="1fr, 1fr, 1fr" templateColumns="1fr, 1fr" gap={4}>
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Check against registry
          </Heading>
        </GridItem>
        <GridItem rowStart={2}>
          <Select
            placeholder="Select a file to upload"
            value={uploadFileName}
            onChange={event => {}}
          >
            {uploadFileName.length > 0 && (
              <option value={uploadFileName}>{uploadFileName}</option>
            )}
          </Select>
        </GridItem>
        <GridItem rowStart={2} colStart={2}>
          <Flex direction="row" columnGap="2em">
            <input
              type="file"
              id="file"
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={event => {
                const fileObj = event.target.files && event.target.files[0];
                if (!fileObj) {
                  return;
                }
                const reader = new FileReader();
                reader.readAsText(fileObj);
                setUploadFileName(fileObj.name);

                reader.onload = function () {
                  if (reader.result) {
                    setPayload(reader.result.toString());
                  }
                };

                reader.onerror = function () {
                  setPayload('');
                  console.error(reader.error);
                };
              }}
            />
            <Button
              colorScheme="blue"
              size="md"
              onClick={() => {
                (inputRef.current as any).click();
              }}
            >
              Upload file
            </Button>
          </Flex>
        </GridItem>
        <GridItem rowStart={3}>
          <Flex direction="row" columnGap="2em">
            {currentState === DeduplicationPageState.UPLOAD_DATA && (
              <Button
                colorScheme="teal"
                size="md"
                isDisabled={!isFormReadyForDeduplication()}
                onClick={startDeduplication}
              >
                Run deduplication
              </Button>
            )}
            {currentState === DeduplicationPageState.CONFIRM_DATA && (
              <Button
                colorScheme="teal"
                size="md"
                isDisabled={!isFormReadyForDeduplication()}
                onClick={startConfirmingData}
              >
                Confirm data
              </Button>
            )}
          </Flex>
        </GridItem>
      </Grid>
    );
  };

  const startDeduplication = async () => {
    setCurrentState(DeduplicationPageState.LOADING);
    const uploadUri = `${configuration.cloudflareWorkerApi.apiUrl}/api/upload`;
    try {
      const response = await axios.post(uploadUri, payload, {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      const uploadResponse = response.data as UploadResponse;
      setResults([
        `${uploadResponse.total} records were submitted`,
        `${uploadResponse.added_to_registry} records were added to the Registry`,
        `${uploadResponse.potential_duplicated} potential duplicates were found`,
      ]);
      setDownloadUri(
        `${configuration.cloudflareWorkerApi.apiUrl}/api/deduplicated/` +
          uploadResponse.working_id
      );
      setTimeout(() => {
        setCurrentState(DeduplicationPageState.REVIEW_RESULTS);
      }, 1000);
    } catch (error: any) {
      toast.error(error.response.data.error);
      setCurrentState(DeduplicationPageState.UPLOAD_DATA);
    } finally {
    }
  };

  const startConfirmingData = async () => {
    setCurrentState(DeduplicationPageState.LOADING);
    const uploadUri = `${configuration.cloudflareWorkerApi.apiUrl}/api/confirm`;
    try {
      const response = await axios.post(uploadUri, payload, {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
      const confirmResponse = response.data as ConfirmResponse;
      setResults([
        `The processed file now has 0 potential duplicates`,
        `Your confirmed records have been added to the registry`,
      ]);
      setTimeout(() => {
        setCurrentState(DeduplicationPageState.REVIEW_RESULTS);
      }, 1000);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const renderLoading = () => {
    return (
      <Grid templateRows="1fr, 1fr, 1fr" templateColumns="1fr, 1fr" gap={4}>
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Registry Deduplication
          </Heading>
        </GridItem>
        <GridItem rowStart={2}>
          <Card>
            <CardBody>
              <Text>
                Uploading Data from <strong>{uploadFileName}</strong>
              </Text>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem rowStart={3}>
          <Progress colorScheme="green" size="md" isIndeterminate />
        </GridItem>
      </Grid>
    );
  };

  const renderResults = () => {
    return (
      <Grid
        templateRows="1fr, 1fr, 1fr, 1fr, 1fr"
        templateColumns="1fr, 1fr"
        gap={4}
      >
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Registry Deduplication
          </Heading>
        </GridItem>
        <GridItem rowStart={2} colSpan={1}>
          <Heading as="h1" size="m" noOfLines={1}>
            You uploaded the file <strong>{uploadFileName}</strong>
          </Heading>
        </GridItem>
        <GridItem rowStart={3} colSpan={2}>
          <Card>
            <CardBody>
              <Text>From your file, we found the following:</Text>
              {_.map(results, (x, k) => {
                return <Text key={k}>{x}</Text>;
              })}
            </CardBody>
          </Card>
        </GridItem>
        {!isConfirming && (
          <>
            <GridItem rowStart={4} colSpan={2}>
              <Card>
                <CardBody>
                  <Text>Download a file of potential duplications</Text>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem rowStart={5} colStart={2}>
              <Flex direction="row" columnGap="2em">
                <Button
                  colorScheme="teal"
                  size="md"
                  onClick={() => {
                    window.open(downloadUri, '_blank');
                  }}
                >
                  Download file
                </Button>
              </Flex>
            </GridItem>
            <GridItem rowStart={6} colSpan={2}>
              <Card>
                <CardBody>
                  <Text>
                    Please check these potential duplicates with your
                    colleagues, make any necessary corrections, and then upload
                    a revised file with us.
                  </Text>
                </CardBody>
              </Card>
            </GridItem>
          </>
        )}
      </Grid>
    );
  };

  const renderCurrentState = () => {
    if (currentState === DeduplicationPageState.EMPTY) {
      return renderEmptyState();
    }
    if (currentState === DeduplicationPageState.UPLOAD_DATA) {
      return renderPrepareDataUpload();
    }
    if (currentState === DeduplicationPageState.LOADING) {
      return renderLoading();
    }
    if (currentState === DeduplicationPageState.REVIEW_RESULTS) {
      return renderResults();
    }
    if (currentState === DeduplicationPageState.CONFIRM_DATA) {
      return renderPrepareDataUpload();
    }
    return renderEmptyState();
  };

  return (
    <Box w={'100%'} padding={'2rem'} overflowY={'auto'}>
      <Flex className="DeduplicationPage" position={'relative'}>
        {renderCurrentState()}
      </Flex>
    </Box>
  );
};

export const DeduplicationPage = withAuthProtection(Page);
