import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    white: "#ffffff",
    black: "#000000",
  },
  sizes: {
    mobile: "600px",
    container: {
      mobile: "600px",
      tablet: "900px",
      md: "1200px",
    },
  },
  components: {
    Button: {
      variants: {
        grey: {
          bg: "#494f4f",
          color: "white",
        },
      },
    },
  },
});
