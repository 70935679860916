import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { Navigate, useLocation } from 'react-router';
import { authentication } from './authentication';
import { LoginUser } from '../data/login-data';

const ProtectedRouteElement: FunctionComponent<
  PropsWithChildren<any>
> = props => {
  const [isAuth, setAuth] = useState<boolean | null>(null);
  const [user, setUser] = useState<LoginUser | null>(null);
  const location = useLocation();

  useEffect(() => {
    const auth = authentication.isAuthenticated();
    if (auth) {
      setUser(authentication.getUser());
    }
    setAuth(auth);
  }, []);

  if (isAuth === null) {
    return <></>;
  }

  if (isAuth === false) {
    return <Navigate to={`/login?path=${location.pathname}`} replace />;
  }

  if (!user?.orgId) {
    return (
      <Navigate
        to={`/login?path=${location.pathname}&error=The user doesn't have any organization assigned.`}
        replace
      />
    );
  }

  return props.children;
};

export const withAuthProtection = (Page: FunctionComponent) => {
  return () => {
    return (
      <ProtectedRouteElement>
        <Page />
      </ProtectedRouteElement>
    );
  };
};
