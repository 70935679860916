import { useEffect, useState } from 'react';
import data from '../../../config/config.json';
import {
  Image,
  Text,
  Center,
  Container,
  Button,
  Stack,
  FormControl,
  FormErrorMessage,
  Link,
  Input,
  Spinner,
  Flex,
  Box,
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import { authentication } from '../../services/authentication';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

export const LoginPage = () => {
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState('');
  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const error = params.get('error');
    if (error) {
      setLoginError(error);
    }
  }, []);

  useEffect(() => {
    if (loginError) toast.error(loginError);
  }, [loginError]);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [badEmailOrPassword, setBadEmailOrPassword] = useState<boolean>(false);

  const [error] = useState('');

  const handleLoggingWithGoogle = async () => {
    await authentication.loginWithGoogle();
  };

  const handleLoggingButtonCLick = async () => {
    try {
      setIsLoading(true);
      await authentication.loginWithUserAndPassword(email, password);
    } catch (error: any) {
      setBadEmailOrPassword(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getContactLink = () => {
    if (data.contactUri === '') {
      return null;
    }
    return (
      <Stack spacing="0.5" align="center">
        <Text fontSize="sm" color="muted">
          Having trouble logging in?
        </Text>
        <Link href={`${data.contactUri}`}>
          <Button
            variant="link"
            leftIcon={<PhoneIcon />}
            color={data.primaryColor}
            size="sm"
          >
            Contact us
          </Button>
        </Link>
      </Stack>
    );
  };

  return (
    <Center height="100vh">
      <Toaster />
      <Container
        maxW="md"
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg-surface' }}
        bgGradient={{
          sm: `linear(to-r, ${data.linearGradient[0]}, ${data.linearGradient[1]})`,
        }}
        boxShadow={{ base: 'none', sm: 'xl' }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="8">
          <Stack spacing="6" align="center">
            <Image
              className="RepLoginImage"
              width="12rem"
              height="5rem"
              objectFit="contain"
              src={data.icon}
              alt={data.companyName}
            />
          </Stack>

          <Stack spacing="6">
            <Stack spacing="4">
              <FormControl isInvalid={!!error}>
                {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
              </FormControl>
              <Input
                isInvalid={badEmailOrPassword}
                isDisabled={isLoading}
                placeholder="Email"
                onChange={event => {
                  setEmail(event.currentTarget.value);
                }}
              ></Input>
              <Input
                isDisabled={isLoading}
                isInvalid={badEmailOrPassword}
                type="password"
                placeholder="Password"
                onChange={event => {
                  setPassword(event.currentTarget.value);
                }}
              ></Input>
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                <Flex gap={3} direction={'column'}>
                  <Button
                    isDisabled={!email || !password}
                    onClick={() => handleLoggingButtonCLick()}
                  >
                    Sign In
                  </Button>
                  <Button
                    className="btnSignInGoogle"
                    onClick={() => handleLoggingWithGoogle()}
                  >
                    Sign In with Google
                  </Button>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    Don't have an account?
                    <Button
                      variant={'ghost'}
                      _hover={{ bg: 'transparent' }}
                      padding={2}
                      onClick={() => navigate('/signup')}
                    >
                      Sign Up
                    </Button>
                  </Box>
                </Flex>
              )}
            </Stack>
          </Stack>
          {getContactLink()}
          <Text fontSize="xs" color="subtle" textAlign="center">
            By continuing, you acknowledge that you have read, understood, and
            agree to our terms and condition
          </Text>
        </Stack>
      </Container>
    </Center>
  );
};
