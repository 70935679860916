import { configuration } from '../../config/configuration';
import { BaseService } from './api-clients/base-service';
import { BearerTokenApiClient } from './api-clients/bearer-token-api-client';

export class OrganizationService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'api');
  }

  public async getOrganizations(): Promise<any> {
    try {
      return await this.apiClient.get('/api/organizations');
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

const client = new BearerTokenApiClient();
client.withBaseUrl(configuration.cloudflareWorkerApi.apiUrl);
export const organizationService = new OrganizationService(client);
