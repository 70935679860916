import {
  Flex,
  Box,
  Button,
  Heading,
  Grid,
  GridItem,
  Card,
  CardBody,
  Spinner,
} from '@chakra-ui/react';
import _ from 'lodash';
import { withAuthProtection } from '../../services/protect-route-element';
import './ReferralInboxPage.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { referralService } from '../../services/referral-service';
import { authentication } from '../../services/authentication';

export const Page = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user = authentication.getUser();
      const data = await referralService.getInbox(user?.orgId ?? 'SC');
      setData(data);
    } catch (error) {
      toast.error('Error getting the inbox.');
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const render = () => {
    return (
      <Grid templateRows="1fr, 1fr" gap={4}>
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="lg" noOfLines={1}>
            Referral Inbox
          </Heading>
        </GridItem>
        <GridItem rowStart={2} colStart={1}>
          {(!isLoading && data && (
            <Flex direction="column" rowGap="2em">
              {(data.length > 0 &&
                _.map(data, (item: any, index) => {
                  return (
                    <Card key={index}>
                      <CardBody>
                        <Grid templateColumns="1fr 1fr" gap={6}>
                          <GridItem w="100%">
                            <strong>
                              {item.firstName} {item.familyName}
                            </strong>{' '}
                            (from {item.originOrganizationId})
                            <br />
                            {item.gender ? `${item.gender}, ` : ''}{' '}
                            {item.dateOfBirth}
                          </GridItem>
                          <GridItem w="100%" textAlign={'right'}>
                            <Button
                              colorScheme="teal"
                              size="md"
                              onClick={() =>
                                navigate(`/referral/review/${item.referralId}`)
                              }
                            >
                              Review
                            </Button>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Card>
                  );
                })) || (
                <>
                  <Card>
                    <CardBody>You have no referrals pending</CardBody>
                  </Card>
                </>
              )}
            </Flex>
          )) || <Spinner />}
        </GridItem>
      </Grid>
    );
  };

  return (
    <Box w={'100%'} padding={'2rem'} overflowY={'auto'}>
      <Flex className="DeduplicationPage" position={'relative'}>
        {render()}
      </Flex>
    </Box>
  );
};

export const ReferralInboxPage = withAuthProtection(Page);
