import {
  Box,
  Heading,
  Spinner,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import './ServicePage.scss';
import { useEffect, useState } from 'react';
import { organizationService } from '../../services/organization-service';

const Page = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const organizations = await organizationService.getOrganizations();
        setOrganizations(organizations);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Box h={'100vh'} overflowY={'auto'} w={'100%'} display={'flex'}>
      <Box
        className="ServicePage"
        display={'flex'}
        flexDirection={'column'}
        gap={{ base: '2rem', xl: '4rem' }}
        maxW={'1500px'}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Box>
            <Heading as="h1" size="xl" noOfLines={1}>
              Organization directory
            </Heading>
            <br />
            {organizations.map((organization: any, index) => (
              <Box marginLeft={'2em'} marginBottom={'2em'} key={index}>
                <Heading as="h3" size="md" noOfLines={1}>
                  {organization.displayName}
                </Heading>
                {organization.services?.length > 0 && (
                  <Box marginLeft={'1em'} marginTop={'1em'} key={index}>
                    <Heading as="h3" size="sm" noOfLines={1}>
                      Services
                    </Heading>
                    <UnorderedList>
                      {(organization.services as string[]).map(
                        (service: string, index) => {
                          return <ListItem key={index}>{service}</ListItem>;
                        }
                      )}
                    </UnorderedList>
                  </Box>
                )}
                {organization.locations?.length > 0 && (
                  <Box marginLeft={'1em'} marginTop={'1em'} key={index}>
                    <Heading as="h3" size="sm" noOfLines={1}>
                      Locations
                    </Heading>
                    <UnorderedList>
                      {(organization.locations as string[]).map(
                        (location: string, index) => {
                          return <ListItem key={index}>{location}</ListItem>;
                        }
                      )}
                    </UnorderedList>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const ServicePage = Page;
