import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { HiHome } from 'react-icons/hi';
import { PiBinoculars } from 'react-icons/pi';
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import { BsUpload } from 'react-icons/bs';
import { VscReferences } from 'react-icons/vsc';

interface NavButtonProps extends ButtonProps {
  icon: string;
  label: string;
  alt?: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const getIcon = (key: string) => {
    if (key === 'home') {
      return HiHome;
    } else if (key === 'upload-data') {
      return BsUpload;
    } else if (key === 'view-data') {
      return PiBinoculars;
    }
    if (key === 'refer') {
      return VscReferences;
    } else {
      return HiOutlineArrowSmRight;
    }
  };
  return (
    <Tooltip label={buttonProps.alt}>
      <Button
        colorScheme="teal"
        justifyContent={isDesktop ? 'start' : 'center'}
        {...buttonProps}
      >
        <HStack spacing="3">
          <Icon as={getIcon(icon)} />
          {isDesktop && <Text>{label}</Text>}
        </HStack>
      </Button>
    </Tooltip>
  );
};
