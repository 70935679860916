export const configuration = {
  cedApiKey: "bb045757ed283a78dae10e8faa221b132610b165118421de12a7ee1b571cd1213826b2f412d9af4393e45f701257ca59604d",
  environment: "ukraine-demo",
  version: "1.0.12",
  aidVerifierPortal: {
    hostingUrl: "https://cb-dp-ukraine-recipient-dev.web.app",
  },
  api: {
    hostingUrl: "https://caribou-api-dev-seks43hxeq-ue.a.run.app",
  },
  cloudflareWorkerApi: {
    apiUrl: "https://cb-dp-ukraine-dev.merthin.workers.dev",
  },
  portalConfigurations: {
    navbarHeadingPrefix: "",
    navbarRightTextLogo: "Ukraine Response Consortium",
  },
  userRegistry: {
    domain: "auth.cb-dp-ukraine-staff-dev.merthin.tech",
    audience: "https://cb-dp-ukraine-dev.eu.auth0.com/api/v2/",
    clientId: "q4RN4CELwbnXGvDQqLZ0RFTzhvzqnswc",
  },
};