import { IContent, ISharedFiles } from '../data/shared-files';
import { BaseService } from './api-clients/base-service';
import { BearerTokenApiClient } from './api-clients/bearer-token-api-client';

export class SharedFilesService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'shared-files');
  }

  addSharedFile(sharedFile: ISharedFiles): Promise<any> {
    const form = new FormData();
    form.append('name', sharedFile.name);
    form.append('uploader', sharedFile.uploader);
    form.append('format', sharedFile.format);
    form.append('content', sharedFile.content);
    form.append('organization', sharedFile.organization);
    form.append('dateModified', sharedFile.dateModified);
    for (let i = 0; i < sharedFile.sharedWith.length; i++) {
      form.append('email', sharedFile.sharedWith[i].email);
      form.append('permission', sharedFile.sharedWith[i].permission);
      form.append('length', sharedFile.sharedWith[i].length);
    }

    const url = this.url('/add');
    return this.apiClient.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  verifySharedFile(content: IContent): Promise<any> {
    const form = new FormData();
    form.append('format', content.format);
    form.append('content', content.content);

    const url = this.url('/verify');
    return this.apiClient.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getSharedFiles(): Promise<ISharedFiles[]> {
    const url = this.url('/list');
    return this.apiClient.get(url);
  }
}
export const sharedFilesService = new SharedFilesService(
  new BearerTokenApiClient()
);
