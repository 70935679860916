import {
  Card,
  Flex,
  Select,
  Image,
  Input,
  Button,
  Box,
  useToast,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Tag,
  Text,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { useState } from 'react';
import { sharedFilesService } from '../../services/shared-files';
import { authentication } from '../../services/authentication';
import { withAuthProtection } from '../../services/protect-route-element';
import './UploadDataPage.scss';
import {
  ISharedWith,
  LengthEnum,
  PermissionEnum,
} from '../../data/shared-files';
import React from 'react';

const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const Page = () => {
  const toast = useToast();
  const [format, setFormat] = useState<string>('None');
  const [name, setName] = useState<string>('');
  const [organization, setOrganization] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [length, setLength] = useState<LengthEnum>(LengthEnum.Seven);
  const [permission, setPermission] = useState<PermissionEnum>(
    PermissionEnum.View
  );
  const [sharedWith, setSharedWith] = useState<ISharedWith[]>([]);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [dataToUpload, setDataToUpload] = useState<string>('');
  const [error, setError] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const {
    isOpen: isSuccessVisible,
    onClose: onCloseSuccess,
    onOpen: onOpenSuccess,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isErrorVisible,
    onClose: onCloseError,
    onOpen: onOpenError,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isSharedWithVisible,
    onClose: onSharedWithClose,
    onOpen: onSharedWithOpen,
  } = useDisclosure({ defaultIsOpen: false });
  const initialSharedWithRef = React.useRef(null);
  const finalSharedWithRef = React.useRef(null);

  const toBase64 = (file: File) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = reject;
    });

  const clean = () => {
    setDataToUpload('');
    setName('');
    setFormat('None');
    setSharedWith([]);
    setOrganization('');
    onCloseError();
    onCloseSuccess();
  };

  const handleSubmit = async () => {
    if (authentication.getUser() !== null && authentication.getUser()?.email) {
      setIsLoading(true);
      try {
        const data = await sharedFilesService.addSharedFile({
          name: name,
          uploader: authentication.getUser()?.email as string,
          sharedWith: sharedWith,
          format: format,
          content: dataToUpload,
          organization: organization,
          dateModified: new Date().toISOString(),
        });
        setHeaders(data.headers);
        if (data.error) {
          setError(data.error);
          onCloseSuccess();
          onOpenError();
          setIsLoading(false);
          return;
        }
        clean();
        toast({
          title: 'New shared file added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } catch (e: any) {
        setError(e.message);
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
      setIsLoading(false);
    } else {
      setError(['There is not email registered']);
      onCloseSuccess();
      onOpenError();
    }
  };

  const addSharedWith = () => {
    if (email.match(validRegex)) {
      setSharedWith([
        ...sharedWith,
        {
          email: email,
          permission: permission,
          length: length,
        },
      ]);
      setIsInvalidEmail(false);
      setEmail('');
      setPermission(PermissionEnum.View);
      setLength(LengthEnum.Seven);
      onSharedWithClose();
    } else {
      setIsInvalidEmail(true);
    }
  };

  const handleVerify = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length > 0) {
      const temp = await toBase64(event.target.files[0]);
      setIsVerifying(true);
      try {
        const data = await sharedFilesService.verifySharedFile({
          format: format,
          content: temp,
        });
        setHeaders(data.headers);
        if (data.error) {
          setError(data.error);
          onCloseSuccess();
          onOpenError();
          setIsVerifying(false);
          return;
        }
        setDataToUpload(temp);
        setName(event.target.files[0].name);
        onCloseError();
        onOpenSuccess();
      } catch (e: any) {
        setError(e.message);
        onCloseSuccess();
        onOpenError();
      }
      setIsVerifying(false);
    }
  };

  return (
    <Box
      h={'100vh'}
      overflowY={'auto'}
      w={'100%'}
      display={'flex'}
      justifyContent={'center'}
    >
      <Flex
        className="UploadDataPage"
        display={'flex'}
        flexDirection={'column'}
        gap={{ base: '2rem', xl: '2rem' }}
        w={'100%'}
        maxW={'1200px'}
      >
        <Text fontWeight={'bold'} fontSize={'2xl'}>
          Upload Data
        </Text>
        <Flex flexDir={'column'}>
          <FormLabel>Pick Data Schema</FormLabel>
          <Select
            cursor={'pointer'}
            onChange={event => {
              setFormat(event.target.value);
            }}
            value={format}
          >
            <option value={'None'}>None</option>
            <option value={'DIGID Data Standard'}>DIGID Data Standard</option>
          </Select>
        </Flex>
        <Flex flexDir={'column'}>
          <FormLabel>Category</FormLabel>

          <Select
            cursor={'pointer'}
            onChange={event => {
              setOrganization(event.target.value);
            }}
            value={format}
          >
            <option value={'List for deduplication'}>
              List for deduplication
            </option>
            <option value={'List for distribution'}>
              List for distribution
            </option>
            <option value={'List to validate targeting'}>
              List to validate targeting
            </option>
            <option value={'List for referrals'}>List for referrals</option>
          </Select>
        </Flex>
        <Flex flexDir={'column'}>
          <Flex alignItems={'center'} gap={'1rem'}>
            <Text fontSize={'2xl'}>Sharing</Text>
            <Button
              colorScheme="blue"
              w={'min-content'}
              onClick={onSharedWithOpen}
            >
              +
            </Button>
          </Flex>
          <Modal
            initialFocusRef={initialSharedWithRef}
            finalFocusRef={finalSharedWithRef}
            isOpen={isSharedWithVisible}
            onClose={onSharedWithClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Shared With</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Flex flexDir={'column'} gap={'1rem'}>
                  <FormControl mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      isInvalid={isInvalidEmail}
                      placeholder="Email"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                    {isInvalidEmail && (
                      <Text color="red" fontSize={'xs'}>
                        Enter email format
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Permission</FormLabel>
                    <RadioGroup
                      onChange={value => setPermission(value as PermissionEnum)}
                      value={permission}
                    >
                      <Stack direction="row">
                        <Radio value={PermissionEnum.View}>View</Radio>
                        <Radio value={PermissionEnum.Edit}>Edit</Radio>
                        <Radio value={PermissionEnum.ManageSharing}>
                          Manage Sharing
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Length</FormLabel>
                    <RadioGroup
                      onChange={value => setLength(value as LengthEnum)}
                      value={length}
                    >
                      <Stack direction="row">
                        <Radio value={LengthEnum.Seven}>7 days</Radio>
                        <Radio value={LengthEnum.Thirty}>30 days</Radio>
                        <Radio value={LengthEnum.Sixty}>60 days</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Flex>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  isDisabled={!email}
                  onClick={() => addSharedWith()}
                >
                  Save
                </Button>
                <Button onClick={onSharedWithClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
        <Flex gap={'0.5rem'} flexWrap={'wrap'}>
          {sharedWith.map((element: ISharedWith) => (
            <Tag>
              {element.email}:{' '}
              {element.permission === 'manageSharing'
                ? 'manage sharing'
                : element.permission}{' '}
              for {element.length} days
              <TagCloseButton
                onClick={() => {
                  const newEmails = sharedWith.filter(
                    x => x.email !== element.email
                  );
                  setSharedWith(newEmails);
                }}
              />
            </Tag>
          ))}
        </Flex>
        <Flex flexDir={'column'} gap={'1rem'}>
          <Text fontSize={'xl'}>Data File</Text>
          <Card
            border={'1px dashed black'}
            h={'30rem'}
            minH={'30rem'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            overflowY={'auto'}
          >
            {isSuccessVisible && (
              <Flex flexDir={'column'} w={'100%'} h={'100%'} gap={'1rem'}>
                <Alert
                  status="success"
                  w={'auto'}
                  borderRadius={'0.5rem'}
                  width={'100%'}
                  height={'min-content'}
                  justifyContent={'space-between'}
                  padding={'1rem'}
                >
                  <AlertIcon />
                  <Box>
                    <AlertTitle>CSV uploaded successfully</AlertTitle>
                  </Box>
                  <CloseButton
                    alignSelf="center"
                    position="relative"
                    onClick={onCloseSuccess}
                  />
                </Alert>
                <Flex alignItems={'center'} flexDir={'column'}>
                  <Text fontWeight={'bold'}>{name}</Text>
                  <Flex
                    flexDir={'column'}
                    padding={'1rem'}
                    gap={'0.5rem'}
                    alignItems={'center'}
                    h={'25rem'}
                    minH={'25rem'}
                    flexWrap={'wrap'}
                  >
                    {headers.map(header => {
                      return (
                        <Tag minW={'11.25rem'} justifyContent={'center'}>
                          {header}
                        </Tag>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            )}
            {isErrorVisible && (
              <Flex flexDir={'column'} w={'100%'} h={'100%'} gap={'1rem'}>
                <Flex flexDir={'column'}>
                  {error.length > 0 ? (
                    error.map(x => (
                      <Alert
                        status="error"
                        w={'auto'}
                        borderRadius={'0.5rem'}
                        width={'100%'}
                        height={'min-content'}
                        justifyContent={'space-between'}
                        padding={'1rem'}
                      >
                        <AlertIcon />
                        <Box>
                          <AlertTitle>{x}</AlertTitle>
                        </Box>
                        <CloseButton
                          alignSelf="center"
                          position="relative"
                          onClick={onCloseError}
                        />
                      </Alert>
                    ))
                  ) : (
                    <Alert
                      status="error"
                      w={'auto'}
                      borderRadius={'0.5rem'}
                      width={'100%'}
                      height={'min-content'}
                      justifyContent={'space-between'}
                      padding={'1rem'}
                    >
                      <AlertIcon />
                      <Box>
                        <AlertTitle>Error!</AlertTitle>
                      </Box>
                      <CloseButton
                        alignSelf="center"
                        position="relative"
                        onClick={onCloseError}
                      />
                    </Alert>
                  )}
                </Flex>
                <Flex alignItems={'center'} flexDir={'column'}>
                  <Text fontWeight={'bold'}>{name}</Text>
                  <Flex
                    flexDir={'column'}
                    padding={'1rem'}
                    gap={'0.5rem'}
                    alignItems={'center'}
                    h={'25rem'}
                    minH={'25rem'}
                    flexWrap={'wrap'}
                  >
                    {headers.map(header => {
                      return (
                        <Tag minW={'11.25rem'} justifyContent={'center'}>
                          {header}
                        </Tag>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Card>
        </Flex>
        <Box
          className="Actions"
          display={'flex'}
          justifyContent={'space-between'}
          paddingBottom={'2rem'}
        >
          <Flex gap={'1rem'}>
            <Button
              colorScheme={'blue'}
              isDisabled={
                !dataToUpload || !format || !name || isLoading || isErrorVisible
              }
              onClick={() => handleSubmit()}
            >
              {`Submit ${isLoading ? '(In Progress...)' : ''}`}
            </Button>
            <Button
              colorScheme={'blue'}
              onClick={() => document.getElementById('file-uploader')?.click()}
              isDisabled={isVerifying}
            >
              <Input
                id="file-uploader"
                accept=".csv"
                display={'none'}
                type="file"
                onClick={event => (event.currentTarget.value = '')}
                onChange={event => handleVerify(event)}
              />
              {!isVerifying ? 'Select file' : 'Verification in progress...'}
            </Button>
            <Button
              variant={'outline'}
              colorScheme={'blue'}
              onClick={() => clean()}
            >
              Cancel
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export const UploadDataPage = withAuthProtection(Page);
