import { Box, Grid, Text, Button } from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import './HomePage.scss';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { useNavigate } from 'react-router-dom';

const Page = () => {
  const navigate = useNavigate();

  const getCards = () => {
    return (
      <Grid className="CardsGrid" gridTemplateColumns={'1fr 1fr'} gap={5}>
        <Button
          padding={5}
          colorScheme={'teal'}
          h={'10rem'}
          onClick={() => navigate('/deduplication')}
        >
          <Text fontSize={'md'}>Deduplication</Text>
        </Button>
        <Button
          padding={5}
          colorScheme={'teal'}
          h={'10rem'}
          onClick={() => navigate('/refer-human')}
        >
          <Text fontSize={'md'}>Referral</Text>
        </Button>
      </Grid>
    );
  };

  return (
    <Box
      h={'100vh'}
      overflowY={'auto'}
      w={'100%'}
      display={'flex'}
      justifyContent={'center'}
    >
      <Box
        className="HomePage"
        display={'flex'}
        flexDirection={'column'}
        gap={{ base: '2rem', xl: '4rem' }}
        maxW={'1500px'}
      >
        {getCards()}
      </Box>
    </Box>
  );
};

export const HomePage = withAuthProtection(Page);
