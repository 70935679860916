import { Box, Grid, Text, Button, Card, CardBody } from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import './WelcomePage.scss';
import { useNavigate } from 'react-router-dom';
import { configuration } from '../../../config/configuration';

const Page = () => {
  const navigate = useNavigate();

  const getCards = () => {
    return (
      <Grid className="CardsGrid" gridTemplateColumns={'1fr 1fr'} gap={5}>
        <div>
          <Card>
            <CardBody>
              <Text>Admin access for deduplication and referrals</Text>
            </CardBody>
          </Card>
          <Button
            padding={5}
            colorScheme={'teal'}
            onClick={() => navigate('/home')}
          >
            <Text fontSize={'md'}>Login</Text>
          </Button>
        </div>
        <div>
          <Card>
            <CardBody>
              <Text>Aid recipient access to view their personal data</Text>
            </CardBody>
          </Card>
          <Button
            padding={5}
            colorScheme={'teal'}
            onClick={() => {
              window.location.replace(
                configuration.aidVerifierPortal.hostingUrl
              );
            }}
          >
            <Text fontSize={'md'}>View data</Text>
          </Button>
        </div>
      </Grid>
    );
  };

  return (
    <Box
      h={'100vh'}
      overflowY={'auto'}
      w={'100%'}
      display={'flex'}
      justifyContent={'center'}
    >
      <Box
        className="HomePage"
        display={'flex'}
        flexDirection={'column'}
        gap={{ base: '2rem', xl: '4rem' }}
        maxW={'1500px'}
      >
        {getCards()}
      </Box>
    </Box>
  );
};

export const WelcomePage = Page;
