import {
  Box,
  Grid,
  Text,
  Button,
  Flex,
  GridItem,
  Stack,
  Input,
  Heading,
  Select,
  Checkbox,
} from '@chakra-ui/react';
import './ReferHumanPage.scss';
import { useState, useEffect } from 'react';
import { withAuthProtection } from '../../services/protect-route-element';
import toast from 'react-hot-toast';
import { authentication } from '../../services/authentication';
import { referralService } from '../../services/referral-service';
import { organizationService } from '../../services/organization-service';
import _ from 'lodash';

const Page = () => {
  const user = authentication.getUser();

  const [recipientQueryData, setRecipientQueryData] = useState<any>({
    mobilePhone: '',
    firstName: '',
    lastName: '',
    birthday: '',
    reason: '',
    receiverOrg: '',
    consent: false,
    location: '',
    service: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const sendReferralHandler = async () => {
    const user = authentication.getUser();
    const response = await referralService.shareReferral({
      originOrganizationId: user?.orgId ?? 'SSRC',
      receiverOrganizationId: recipientQueryData.receiverOrg,
      firstName: recipientQueryData.firstName,
      familyName: recipientQueryData.lastName,
      mobilePhone: recipientQueryData.mobilePhone,
      dateOfBirth: recipientQueryData.birthday,
      reason: recipientQueryData.reason,
      service: recipientQueryData.service,
      location: recipientQueryData.location,
      consent: recipientQueryData.consent,
    });

    if (response?.success) {
      toast.success('Referral sent!');
      setRecipientQueryData({
        mobilePhone: '',
        firstName: '',
        lastName: '',
        birthday: '',
        reason: '',
        receiverOrg: '',
        location: '',
        service: '',
        consent: false,
      });
    } else {
      toast.error('Error sharing referral.');
    }
  };

  const updateQueryDataByField =
    (recipientFieldKey: string) => (event: any) => {
      setRecipientQueryData({
        ...recipientQueryData,
        ...{ [recipientFieldKey]: event.target.value },
      });
    };

  const updateQueryDataByFieldCheckBox =
    (recipientFieldKey: string) => (event: any) => {
      setRecipientQueryData({
        ...recipientQueryData,
        ...{ [recipientFieldKey]: event.target.checked },
      });
    };

  const updateOrganization = (event: any) => {
    const organization = (organizations as any[]).find(
      org => org.organizationId === event.target.value
    );
    setLocations(organization.locations);
    setServices(organization.services);
    updateQueryDataByField('receiverOrg')(event);
  };

  const [organizations, setOrganizations] = useState([]);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const organizations = await organizationService.getOrganizations();
      const filteredOrganizations = _.filter(organizations, (x: any) => {
        return x.organizationId !== user?.orgId;
      }) as any;
      setOrganizations(filteredOrganizations);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const OrganizationSelect = () => (
    <Select
      placeholder="Select organization to refer to"
      onChange={updateOrganization}
      value={recipientQueryData.receiverOrg}
    >
      {isLoading ? (
        <></>
      ) : (
        organizations.map((organization: any, i) => {
          return (
            <option value={organization.organizationId} key={i}>
              {organization.displayName}
            </option>
          );
        })
      )}
    </Select>
  );

  const ServiceSelect = () => (
    <Select
      placeholder="Select service that is requested"
      onChange={updateQueryDataByField('service')}
      value={recipientQueryData.service}
    >
      {isLoading ? (
        <></>
      ) : (
        services.map((service: any, i) => {
          return (
            <option value={service} key={i}>
              {service}
            </option>
          );
        })
      )}
    </Select>
  );

  const LocationSelect = () => (
    <Select
      placeholder="Select location where service is requested"
      onChange={updateQueryDataByField('location')}
      value={recipientQueryData.location}
    >
      {isLoading ? (
        <></>
      ) : (
        locations.map((location: any, i) => {
          return (
            <option value={location} key={i}>
              {location}
            </option>
          );
        })
      )}
    </Select>
  );

  const renderEmptyState = () => {
    return (
      <Grid
        templateRows="1fr, 1fr, 1fr, 1fr, 1fr"
        templateColumns="1fr, 1fr"
        gap={4}
      >
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Refer Aid Recipient
          </Heading>
        </GridItem>
        <GridItem rowStart={3} colSpan={2}>
          <Stack spacing={3}>
            <Text fontWeight="bold">Record to refer</Text>
            <Input
              placeholder="First Name"
              size="md"
              value={recipientQueryData?.firstName}
              onChange={updateQueryDataByField('firstName')}
            />
            <Input
              placeholder="Last Name"
              size="md"
              value={recipientQueryData?.lastName}
              onChange={updateQueryDataByField('lastName')}
            />
            <Input
              placeholder="Phone Number"
              size="md"
              type="tel"
              value={recipientQueryData?.mobilePhone}
              onChange={updateQueryDataByField('mobilePhone')}
            />
            <Input
              placeholder="Date of Birth"
              size="md"
              type="date"
              value={recipientQueryData?.birthday}
              onChange={updateQueryDataByField('birthday')}
            />
            <Text fontWeight="bold">Organization</Text>
            <OrganizationSelect />

            <Text fontWeight="bold">Service Requested</Text>
            <ServiceSelect />

            <Text fontWeight="bold">Location</Text>
            <LocationSelect />

            <Text fontWeight="bold">Reason for Referral</Text>
            <Stack spacing={3} direction="row" border="1px solid #f2f2f2">
              <textarea
                cols={40}
                rows={5}
                placeholder="Type reason here"
                value={recipientQueryData.reason}
                onChange={updateQueryDataByField('reason')}
              />
            </Stack>
            <Checkbox
              colorScheme="teal"
              onChange={updateQueryDataByFieldCheckBox('consent')}
            >
              Has consent been given?
            </Checkbox>
          </Stack>
        </GridItem>
        <GridItem rowStart={4} colStart={1}>
          <Flex direction="row" columnGap="2em">
            <Button
              colorScheme="teal"
              size="md"
              isLoading={isLoading}
              onClick={sendReferralHandler}
            >
              Share
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    );
  };

  const renderCurrentState = () => {
    return renderEmptyState();
  };

  return (
    <Box w={'100%'} padding={'2rem'} overflowY={'auto'}>
      <Flex className="HomePage" position={'relative'}>
        {renderCurrentState()}
      </Flex>
    </Box>
  );
};

export const ReferHumanPage = withAuthProtection(Page);
