import {
  Flex,
  Box,
  Button,
  Heading,
  Grid,
  GridItem,
  Card,
  CardBody,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import { withAuthProtection } from '../../services/protect-route-element';
import './ReferralReviewPage.scss';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { referralService } from '../../services/referral-service';
import { configuration } from '../../../config/configuration';

export const Page = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [editable, setEditable] = useState(true);
  const [data, setData] = useState<any>(null);
  const [downloadUri, setDownloadUri] = useState('');

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    if (id) {
      try {
        const data = await referralService.getReferral(id);
        setData(data);
        setEditable(data.status === 'pending' || data.status === 'viewed');
        if (data.status === 'pending') {
          await referralService.setReferralStatus(id, 'viewed');
        }
      } catch (error) {
        toast.error('Error getting the inbox.');
        console.log(error);
      }
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    setDownloadUri(
      `${configuration.cloudflareWorkerApi.apiUrl}/api/referral/download/${id}`
    );
    fetchData();
  }, [fetchData, id]);

  const setStatus = async (status: 'accepted' | 'rejected') => {
    if (id) {
      try {
        await referralService.setReferralStatus(id, status);
      } catch (error) {
        toast.error('Error accepting or rejecting referral.');
        console.log(error);
      }
    }
  };

  const render = () => {
    return (
      <Grid templateRows="1fr, 1fr, 1fr, 1fr, 1fr" gap={4}>
        <GridItem rowStart={1} colSpan={1}>
          <Heading as="h1" size="xl" noOfLines={1}>
            Aid Recipient Profile
          </Heading>
        </GridItem>
        {(!isLoading && (
          <>
            <GridItem rowStart={2} colSpan={1}>
              {data.status === 'accepted' && (
                <Badge colorScheme="green" fontSize="xl">
                  Referral accepted
                </Badge>
              )}
              {data.status === 'rejected' && (
                <Badge colorScheme="red" fontSize="xl">
                  Referral rejected
                </Badge>
              )}
            </GridItem>
            <GridItem rowStart={3} colSpan={1}>
              <Card>
                <CardBody>
                  <strong>
                    {data.firstName} {data.familyName}
                  </strong>{' '}
                  (from {data.originOrganizationId})
                  <br />
                  {data.gender ? `${data.gender}, ` : ''} {data.dateOfBirth}
                  <br />
                  <strong>Reason</strong>
                  <br />
                  {data.reason}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem rowStart={4} colSpan={1}>
              <Grid
                templateColumns="repeat(2, 1fr)"
                templateRows="repeat(2, 1fr)"
                gap={6}
              >
                {editable && (
                  <>
                    <GridItem colSpan={1} width={'100%'}>
                      <Button
                        colorScheme="yellow"
                        width={'100%'}
                        onClick={async () => {
                          setIsLoading(true);
                          await setStatus('rejected');
                          await fetchData();
                        }}
                      >
                        Reject referral
                      </Button>
                    </GridItem>
                    <GridItem width={'100%'}>
                      <Button
                        colorScheme="teal"
                        width={'100%'}
                        onClick={async () => {
                          setIsLoading(true);
                          await setStatus('accepted');
                          await fetchData();
                        }}
                      >
                        Accept referral
                      </Button>
                    </GridItem>
                  </>
                )}
                <GridItem width={'100%'}></GridItem>
                <GridItem width={'100%'}>
                  <Button
                    colorScheme="teal"
                    width={'100%'}
                    onClick={() => {
                      window.open(downloadUri, '_blank');
                    }}
                  >
                    Download info
                  </Button>
                </GridItem>
              </Grid>
            </GridItem>
          </>
        )) || <Spinner />}
      </Grid>
    );
  };

  return (
    <Box w={'100%'} padding={'2rem'} overflowY={'auto'}>
      <Flex className="DeduplicationPage" position={'relative'}>
        {render()}
      </Flex>
    </Box>
  );
};

export const ReferralReviewPage = withAuthProtection(Page);
