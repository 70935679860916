import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Image,
  Avatar,
  useBreakpointValue,
  Icon,
  Button,
  Heading,
  Tooltip,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { NavButton } from './NavButton';
import data from '../../../config/config.json';
import { useNavigate } from 'react-router-dom';
import { authentication } from '../../services/authentication';
import { useEffect } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { configuration } from '../../../config/configuration';

export const Navbar = () => {
  const navigate = useNavigate();
  const user = authentication.getUser();
  const analytics = useAnalytics();
  const pathArray = window.location.pathname.split('/');
  const isDesktop = useBreakpointValue({ base: false, xl: true });

  useEffect(() => {
    if (analytics && user) {
      analytics.identify(user.sub, {
        email: user.email,
        name: user.name,
      });
    }
  }, [user?.sub]);

  const getOrgName = () => {
    if (user?.orgId) {
      return user?.orgId;
    } else {
      return '';
    }
  };

  return (
    <Flex as="section" minH="100vh" bg="#fff;" borderRight={'1px solid black'}>
      <Flex
        flex="1"
        bg="bg-surface"
        boxShadow="sm"
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '2' }}
        px={{ base: '4', sm: '6' }}
        w={isDesktop ? 'auto' : '90px'}
      >
        <Stack justify="space-between" spacing="1" width="100%">
          <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
            <Stack align="center" border={'1px solid black'}>
              <Heading as="h1" size="m" noOfLines={2} padding={'1em'}>
                UKR
                {/* {configuration.portalConfigurations.navbarHeadingPrefix}
                {getOrgName()} */}
              </Heading>
            </Stack>
            <Stack spacing="1" gap={'1rem'}>
              {data.Navbar.map(option => {
                return (
                  <NavButton
                    key={option.slug}
                    aria-current={
                      pathArray[1] === option.displayName ? 'page' : 'false'
                    }
                    isDisabled={option?.disabled}
                    label={option.displayName}
                    icon={option.icon}
                    alt={option.alt}
                    onClick={() => {
                      navigate(`/${option.slug}`);
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Stack spacing={{ base: '5', sm: '6' }}>
            <Tooltip label="Logout">
              <Button
                colorScheme="blue"
                onClick={() => {
                  authentication.logout();
                }}
              >
                <Icon
                  margin={1}
                  as={FiLogOut}
                  boxSize="3"
                  color="white"
                  height={5}
                  w={5}
                />
                {isDesktop ? 'Logout' : ''}
              </Button>
            </Tooltip>
            <Divider />
            <HStack spacing="3">
              <Avatar
                boxSize="10"
                name={user?.name || ''}
                src={user?.picture || ''}
              />
              {isDesktop && (
                <Box>
                  <Text textAlign="left" fontWeight="medium" fontSize="sm">
                    {user?.name}
                  </Text>
                  <Text color="muted" fontSize="sm">
                    {user?.email}
                  </Text>
                </Box>
              )}
            </HStack>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
