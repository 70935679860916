import { configuration } from '../../config/configuration';
import { Reference } from '../data/reference';
import { BaseService } from './api-clients/base-service';
import { BearerTokenApiClient } from './api-clients/bearer-token-api-client';

export class ReferralService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'api');
  }

  public async shareReferral(reference: Reference): Promise<any> {
    return await this.apiClient.post('/api/referral/share', reference, {
      validateStatus: () => true,
    });
  }

  public async getInbox(organization: string): Promise<any> {
    return await this.apiClient.get(`/api/referral/inbox/${organization}`, {
      validateStatus: () => true,
    });
  }

  public async getReferral(referralId: string): Promise<any> {
    return await this.apiClient.get(`/api/referral/${referralId}`, {
      validateStatus: () => true,
    });
  }

  public async setReferralStatus(
    referralId: string,
    status: 'accepted' | 'rejected' | 'viewed' | 'pending'
  ): Promise<any> {
    return await this.apiClient.post(
      `/api/referral/set/status/${referralId}`,
      { status },
      {
        validateStatus: () => true,
      }
    );
  }
}

const client = new BearerTokenApiClient();
client.withBaseUrl(configuration.cloudflareWorkerApi.apiUrl);
export const referralService = new ReferralService(client);
