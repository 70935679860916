import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { HomePage } from './Pages/HomePage/HomePage';
import { LoginPage } from './Pages/LoginPage/LoginPage';
import { SignupPage } from './Pages/SignupPage/SignupPage';
import { LoginCallbackPage } from './Pages/LoginCallBackPage/LoginCallbackPage';
import { PageNotFoundPage } from './Pages/PageNotFound/PageNotFound';
import { EmptyLayout } from './Layouts/EmptyLayout/EmptyLayout';
import { MainLayout } from './Layouts/MainLayout/MainLayout';
import { UploadDataPage } from './Pages/UploadDataPage/UploadDataPage';
import { DeduplicationPage } from './Pages/DeduplicationPage/DeduplicationPage';
import { ReferralPage } from './Pages/ReferralPage/ReferralPage';
import { ReferHumanPage } from './Pages/ReferHumanPage/ReferHumanPage';
import { WelcomePage } from './Pages/WelcomePage/WelcomePage';
import { ServicePage } from './Pages/ServicePage/ServicePage';
import { ReferralReviewPage } from './Pages/ReferralReview/ReferralReviewPage';
import { ReferralInboxPage } from './Pages/ReferralInboxPage/ReferralInboxPage';

export const Kernel = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="welcome" element={<WelcomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
          <Route index element={<WelcomePage />} />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route path="home" element={<HomePage />} />
          <Route path="upload-data" element={<UploadDataPage />} />
          <Route path="deduplication" element={<DeduplicationPage />} />
          <Route path="referrals" element={<ReferralPage />} />
          <Route path="refer-human" element={<ReferHumanPage />} />
          <Route path="services" element={<ServicePage />} />
          <Route path="referral/inbox" element={<ReferralInboxPage />} />
          <Route path="referral/review/:id" element={<ReferralReviewPage />} />
          <Route index element={<HomePage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
